body {
  font-family: 'SF UI Display', 'SF UI Text', 'SF Pro Text', 'SF Pro Icons', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  width: calc(100vw - 30px);
  max-width: calc(100vw - 30px);
  margin: 0;
  padding: 30px;
  padding-left: 0px;
  min-width: calc(100vw - 30px);
  display: flex;
}

#root {
  flex-grow: 1;
  display: flex;
  width: 100%;
  max-height: calc(100vh - 10px);
}

.loadingPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  position: relative;
}

.progressContainer {
  position: absolute;
  top: calc(50% + 90px);
  /* Suma del centro del contenedor, la mitad de la altura de main y el espaciado */
  left: 50%;
  transform: translateX(-50%);
}

.progressContainerMovile {
  margin-top: 50px;
  margin-left: 30px;
}

.page-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  gap: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

a {
  color: #DB1C76;
  text-decoration: none;
  font-weight: 500;
}

a:hover {
  color: #0F9FAF;
}

.centrarGrid {
  display: grid;
  place-items: center;
}

.gridModalArgumentappAcciones {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.centrarFlexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  body {
    min-height: calc(100vh - 20px);
    max-height: calc(100vh - 20px);
    width: calc(100vw - 20px);
    max-width: calc(100vw - 20px);
    min-width: calc(100vw - 20px);
    margin: 0;
    padding: 10px;
  }

  .page-container {
    padding: 5px;
  }

}
